import React, { FC } from "react";
import rightImg from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import AagymImg from "images/aagym.png"

export interface SectionOurFeaturesProps {
  className?: string;
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "py-4",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col lg:flex-row items-center ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="lg:w-96 lg:h-96 w-full">
        <img src={AagymImg} alt="Aa Gym" className="rounded-xl w-full"/>
      </div>
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:pl-16 lg:w-2/3 mt-12 lg:mt-0">
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          Eco Sunnah Living
        </span>
        <h2 className="font-semibold text-4xl mt-5">Abdullah Gymnastiar</h2>

        <ul className="space-y-10">
          <li className="space-y-4">
            {/* <Badge name="Advertising" /> */}
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              "Seseorang tidak akan berubah lebih baik, kecuali dia punya
              keberanian untuk melihat kekurangan dirinya."
            </span>
          </li>
          <li className="space-y-4">
            {/* <Badge name="Advertising" /> */}
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              "Marilah kita mulai kejujuran dari hal yang kecil sekalipun,
              pastikan tak ada dusta yang terucap, setiap perkataan terjamin
              kebenarannya."
            </span>
          </li>

          {/* <li className="space-y-4">
            <Badge color="green" name="Exposure " />
            <span className="block text-xl font-semibold">
              Reach millions with Chisfis
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Millions of people are searching for unique places to stay around
              the world
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="red" name="Secure" />
            <span className="block text-xl font-semibold">
              Secure and simple
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              A Holiday Lettings listing gives you a secure and easy way to take
              bookings and payments online
            </span>
          </li> */}
        </ul>
      </div>
    </div>
  )
};

export default SectionOurFeatures;
