import React from 'react';

const ErrorMessage = ({ children, className = '' }) => {
  return (
    <div
      className={`rounded-lg bg-red-50 border border-red-400 p-3 ${className}`}
    >
      <p className="text-red-400">{children}</p>
    </div>
  );
};

export default ErrorMessage;
