import React, { FC, useState, useEffect } from "react";
// import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import Badge from "../../../shared/Badge/Badge";
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
// import LikeSaveBtns from "../../ListingDetailPage/LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { useParams, useHistory } from "react-router-dom";
import { PRODUCT_URL } from "../../../utils/endpoint";
import axios from "axios";
import NumberFormat from "react-number-format";
import { LOCALSTORAGE_USERDETAIL_ECOSUNNAH } from "utils/Types";

export interface ListingStayDetailPageRaferalProps {
  className?: string;
  isPreviewMode?: boolean;
}

const productCheckOutHandler = (history: any, product: any, raferal: any) => {
  history.push(`/checkout-information/${product.id}/${raferal}`);
};
const ListingStayDetailPageRaferal: FC<ListingStayDetailPageRaferalProps> = ({ className = "", isPreviewMode }) => {
  const params: any = useParams();
  const history: any = useHistory();
  console.log('params', params)

  const [product, setProduct]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  // console.log("send data", product);
  const getUser: any = localStorage.getItem(LOCALSTORAGE_USERDETAIL_ECOSUNNAH);

  useEffect(() => {
    axios
      .get(`${PRODUCT_URL}${params.id}`)
      .then((res: any) => {
        setProduct(res.data);
        setLoading(true);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const createMarkup = (htmlString:string) => {
    return { __html: htmlString }
  }

  return (
    <div
      className={`nc-ListingStayDetailPageRaferal  ${className}`}
      data-nc-id="ListingStayDetailPageRaferal"
    >
      {/* SINGLE HEADER */}
      {loading && product.image_product_detail.length > 0 && (
        <>
          <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
              <div
                className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                {product.image_product_detail.length > 0 && (
                  <NcImage
                    containerClassName="absolute inset-0"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl"
                    src={product.thumbnail}
                    prevImageHorizontal
                  />
                )}
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {product.image_product_detail.map((image: any, id: any) => (
                <div
                  key={id}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    id >= 3 ? 'hidden sm:block' : ''
                  }`}
                >
                  <NcImage
                    containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl "
                    src={image.image || ''}
                    prevImageHorizontal
                  />

                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={() => handleOpenModal(id)}
                  />
                </div>
              ))}
            </div>
          </header>
          {/* MODAL PHOTOS */}
          {
            <ModalPhotos
              imgs={product.image_product_detail}
              isOpen={isOpen}
              onClose={handleCloseModal}
              initFocus={openFocusIndex}
            />
          }
        </>
      )}

      {/* MAIn */}
      <main className="container mt-11 flex mb-8">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {/* {renderSection1(loading && product)} */}
          {loading && product !== undefined && (
            <>
              <div className="listingSection__wrap !space-y-6">
                {/* 1 */}
                <div className="flex justify-between items-center">
                  <Badge name={product.unit} />
                  {/* <LikeSaveBtns /> */}
                </div>

                {/* 2 */}
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                  {product.name}
                </h2>

                {/* 3 */}
                <div className="flex items-center space-x-4">
                  {/* <StartRating /> */}
                  <span></span>
                  {product.valid_until}
                  <span>
                    <i className="las la-map-marker-alt"></i>
                    <span className="ml-1"> {product.location}</span>
                  </span>
                </div>

                {/* 4 */}
                <div className="flex items-center">
                  {/* <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" /> */}
                  <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
                    Partner by{' '}
                    <span className="text-neutral-900 dark:text-neutral-200 font-medium">
                      {loading && product.partner_detail.name}
                    </span>
                  </span>
                </div>

                {/* 5 */}
                <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

                {/* 6 */}
                <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
                  {loading &&
                    product.facility_detail.map((facility: any, id: any) => (
                      <div key={id} className="flex items-center space-x-3 ">
                        <i>
                          <img
                            src={facility.icon}
                            alt=""
                            width="20dp"
                            height="20dp"
                          />
                        </i>
                        <span className="">
                          <span className="hidden sm:inline-block">
                            {facility.name}
                          </span>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div className="listingSection__wrap">
                <h2 className="text-2xl font-semibold">Stay information</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="text-neutral-6000 dark:text-neutral-300" dangerouslySetInnerHTML={createMarkup(product?.description)}>
                  {/* <br />
                    <br />
                    <span>
                      There is a private bathroom with bidet in all units, along with a
                      hairdryer and free toiletries.
                    </span>
                    <br /> <br />
                    <span>
                      The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental
                      service and a car rental service are available at the accommodation,
                      while cycling can be enjoyed nearby.
                    </span> */}
                </div>
              </div>
            </>
          )}
          {/* {renderSection2(loading && product)} */}
        </div>

        {/* SIDEBAR */}
        {loading && product !== undefined && (
          <div className="hidden lg:block flex-grow">
            <div className="sticky top-24">
              {' '}
              <div className="listingSection__wrap shadow-xl">
                {/* PRICE */}
                <div className="flex justify-between">
                  <span className="text-3xl font-semibold">
                    <NumberFormat
                      value={product.price_idr}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'Rp'}
                    />
                    <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                      /{product.unit}
                    </span>
                  </span>
                  {/* <StartRating /> */}
                </div>

                {/* FORM */}
                {/* <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
            <StayDatesRangeInput
              wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
              onChange={(date) => setSelectedDate(date)}
              numberOfMonths={1}
              fieldClassName="p-5"
              defaultValue={selectedDate}
              anchorDirection={windowSize.width > 1400 ? "left" : "right"}
            />
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
            <GuestsInput
              fieldClassName="p-5"
              defaultValue={{
                guestAdults: 1,
                guestChildren: 2,
                guestInfants: 0,
              }}
            />
          </form> */}

                {/* SUM */}
                <div className="flex flex-col space-y-4">
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>{product.name}</span>
                    <span>
                      <NumberFormat
                        value={product.price_idr}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp'}
                      />
                    </span>
                  </div>
                  <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                  <div className="flex justify-between font-semibold">
                    <span>Total</span>
                    <span>
                      <NumberFormat
                        value={product.price_idr}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp'}
                      />
                    </span>
                  </div>
                </div>

                {/* SUBMIT */}
                {getUser ? (
                  <ButtonPrimary
                    onClick={() => productCheckOutHandler(history, product, params.raferal)}
                  >
                    Order
                  </ButtonPrimary>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && (
        <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
          <div className="container flex items-center justify-between">
            <NumberFormat
              value={product.price_idr}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'Rp'}
            />
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /{product.unit}
            </span>

            {getUser ? (
              <ButtonPrimary
                onClick={() => productCheckOutHandler(history, product, params.raferal)}
              >
                Order
              </ButtonPrimary>
            ) : null}
          </div>
        </div>
      )}

      {/* OTHER SECTION */}
      {/* {!isPreviewMode && (
        <div className="container py-24 lg:py-32">

          <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Explore by types of stays"
              subHeading="Explore houses based on 10 types of stays"
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
            />
          </div>


          <SectionSubscribe2 className="pt-24 lg:pt-32" />
        </div>
      )} */}
    </div>
  )
};

export default ListingStayDetailPageRaferal;
