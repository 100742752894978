import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import Heading from "components/Heading/Heading";
import { TaxonomyType } from "data/types";
import React from "react";
import IconCoach from "../../images/iconfasilitas/coach.png";
import IconHelm from "../../images/iconfasilitas/helmet.png";
import IconBoots from "../../images/iconfasilitas/boots.png";
import IconGloves from "../../images/iconfasilitas/gloves.png";

export interface SectionGridCategoryBoxProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Pelatih",
    taxonomy: "category",
    count: 1882,
    thumbnail: IconCoach,
  },
  {
    id: "2",
    href: "#",
    name: "Helm",
    taxonomy: "category",
    count: 8288,
    thumbnail: IconHelm,
  },
  {
    id: "3",
    href: "#",
    name: "Sepatu",
    taxonomy: "category",
    count: 1288,
    thumbnail: IconBoots,
  },
  {
    id: "4",
    href: "#",
    name: "Sarung Tangan",
    taxonomy: "category",
    count: 112,
    thumbnail: IconGloves,
  },
];

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categories = DEMO_CATS,
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;

    default:
      CardComponentName = CardCategoryBox1;
  }

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading
        desc="Nikmati Layanan Kami, Dengan Fasilitas Yang Terbaik"
        isCenter={headingCenter}
      >
        Fasilitas Kami
      </Heading>
      <div className="flex flex-wrap justify-center gap-4">
        {categories.map((item: any, i) => (
          <CardComponentName
            key={i}
            taxonomy={item}
            className="mb-6 w-full md:w-64 xl:w-72"
            // Add additional styling props as needed
          />
        ))}
      </div>
    </div>
  )
};

export default SectionGridCategoryBox;
