import React, { Component } from 'react'

export class Copyright extends Component {
    render() {
        return (
            <div className="container justify-content-center items-center">
                Copyright @2021 by Ghaidas
            </div>
        )
    }
}

export default Copyright
