// export const BASE_URL = 'http://ec2-13-213-43-9.ap-southeast-1.compute.amazonaws.com/';
export const BASE_URL = 'https://api.ghaidas.com/';
export const API_URL = `${BASE_URL}api-ghaidas/`;

// product
export const PRODUCT_URL = `${API_URL}product/`;

// community
export const COMMUNITY_URL = `${API_URL}community/`;

// schedule
export const SCHEDULE_URL = `${API_URL}schedule/`;
export const SCHEDULEBYTYPE_URL = `${API_URL}schedulebytype/`;

// horse
export const HORSE_URL = `${API_URL}horse/`;

// trainer
export const TRAINER_URL = `${API_URL}trainer/`;

//AUTH
export const AUTH_URL_LOGIN = `${BASE_URL}auth-ghaidas/token/login/`;
export const AUTH_URL_USERME = `${BASE_URL}auth-ghaidas/users/me/`;
export const AUTH_URL_SIGNUP = `${BASE_URL}auth-ghaidas/users/`;

//Order
export const ORDER_URL = `${API_URL}order/`;

// Facility
export const FACILITY_URL = `${API_URL}facility/`
