import React, { FC } from "react";

export interface SaleOffBadgeProps {
  className?: string;
  status?: string;
}

const SaleOffBadge: FC<SaleOffBadgeProps> = ({
  className = "",
  status = "Available Horse",
}) => {
  const isAvailable = status === "Available Horse";
  return (
    <div
      className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 bg-red-700 text-red-50 rounded-full ${className}`}
      data-nc-id="SaleOffBadge"
    >
      {isAvailable ? "Tersedia" : "Tidak tersedia"}
    
    </div>
  );
};

export default SaleOffBadge;
