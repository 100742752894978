import { FC, useState, useEffect } from "react";
import NumberFormat from 'react-number-format';
// import Badge from "../../shared/Badge/Badge";
import axios from "axios";
import {ORDER_URL} from "../../utils/endpoint";
import { useParams } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import NcImage from "shared/NcImage/NcImage";

export interface ShoppingBagProps {
  className?: string;
}

export interface OrderItem {
  id: number,
  created_at: string,
  updated_at: string,
  order_id: string,
  status: string,
  payment_url: string,
  amount: number,
  user_id: number,
  product_id: number,
  tanggal_book: string,
  jumlah_pax: number,
}


const ShoppingBag: FC<ShoppingBagProps> = ({ className = "" }) => {
  // const [userName, setUserName] = useState('Noname');
  const [orderList, setOrderList] = useState<any>({});
  const { id } = useParams<any>()
  useEffect(() => {
    // const userdetail = JSON.parse(localStorage.getItem("LOCALSTORAGE_USERDETAIL_ECOSUNNAH") || '{}');
    // setUserName(userdetail.fullname);

    const token = localStorage.getItem("LOCALSTORAGE_TOKEN_ECOSUNNAH");
    axios.get(ORDER_URL + id , { headers: { Authorization: `Token ${token}` } }).then((userData: any) => {
      const orderdata = userData.data;
      console.log(orderdata);
      setOrderList(orderdata);
    })
  }, []);

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  // console.log("oerder", orderList)
  return (
    <div
      className={`nc-ShoppingBag container pb-24 lg:pb-32 ${className}`}
      data-nc-id="ShoppingBag"
    >
      {/* <header className="text-center max-w-2xl mx-auto my-20">
        <strong>Hallo, {userName}</strong>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Lihat Pesananmu disini
        </span>
      </header> */}
      <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
        <div
          key={orderList.id}
          className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8 mb-8"
        >
          <h2 className="text-3xl lg:text-4xl font-semibold">Lakukan Pembayaran</h2>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          {/* ------------------------ */}
          <div className="space-y-6">
            {/* <h3 className="text-2xl font-semibold">Your booking</h3>
            <div className="flex flex-col sm:flex-row sm:items-center">
              <div className="flex-shrink-0 w-full sm:w-40">
                <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                  <NcImage src="https://images.pexels.com/photos/6373478/pexels-photo-6373478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
                </div>
              </div>
              <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
                <div>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                    Hotel room in Tokyo, Jappan
                  </span>
                  <span className="text-base sm:text-lg font-medium mt-1 block">
                    The Lounge & Bar
                  </span>
                </div>
                <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                  2 beds · 2 baths
                </span>
                <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                <StartRating />
              </div>
            </div> */}
            <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
              <div className="flex-1 p-5 flex space-x-4">
                <svg
                  className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                    stroke="#D1D5DB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className="flex flex-col">
                  <span className="text-sm text-neutral-400">Tanggal</span>
                  <span className="mt-1.5 text-lg font-semibold">
                    {orderList.tanggal_book}
                  </span>
                </div>
              </div>
              <div className="flex-1 p-5 flex space-x-4">
                <svg
                  className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                    stroke="#D1D5DB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className="flex flex-col">
                  <span className="text-sm text-neutral-400">Jumlah Orang</span>
                  <span className="mt-1.5 text-lg font-semibold">
                    {orderList.jumlah_pax} pax
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* ------------------------ */}
          <div className="space-y-6">
            <h3 className="text-2xl font-semibold">Detail Pesanan</h3>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              {orderList.created_at}
            </span>
            <div className="flex flex-col space-y-4">
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Kode Pesanan</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {orderList.order_id}
                </span>
              </div>
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Tanggal</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {orderList.tanggal_book}
                </span>
              </div>
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Total</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  <NumberFormat
                    value={orderList.amount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Rp'}
                  />
                </span>
              </div>
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Status</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {orderList.status}
                  {/* <NumberFormat value={orderList.amount} displayType={'text'} thousandSeparator={true} prefix={'Rp'} /> */}
                </span>
              </div>

              {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Payment method</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  Credit card
                </span>
              </div> */}
            </div>
          </div>
          <div className="flex text-neutral-6000 dark:text-neutral-300">
            <span className="flex-1">
              <div className="mt-3 pl-4">
                {orderList.status === 'Expired' ? (
                  <ButtonSecondary disabled>Sorry, Expired</ButtonSecondary>
                ) : orderList.status === 'Belum Lunas' ? (
                  <ButtonPrimary
                    onClick={() => {
                      openInNewTab(orderList.payment_url)
                    }}
                  >
                    Bayar
                  </ButtonPrimary>
                ) : null}
              </div>
            </span>
            {/* <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
              <div className="mt-3 pl-4">
                {order.status === 'Belum Lunas' && 
                <ButtonPrimary onClick={() => { openInNewTab(order.payment_url); } }>
                  Bayar
                </ButtonPrimary>}
              </div>
            </span> */}
          </div>
        </div>
      </section>
    </div>
  )
};

export default ShoppingBag;
