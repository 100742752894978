import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";
import { Scheduler } from "@aldabil/react-scheduler";
import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import moment from "moment";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BedroomBabyOutlinedIcon from "@mui/icons-material/BedroomBabyOutlined";
import CastForEducationOutlinedIcon from "@mui/icons-material/CastForEducationOutlined";
import Input from "shared/Input/Input";
import { Spinner } from "reactstrap";
import Select from "shared/Select/Select";
import { LOCALSTORAGE_TOKEN_ECOSUNNAH, LOCALSTORAGE_USERDETAIL_ECOSUNNAH } from "utils/Types";
import { SCHEDULE_URL } from "utils/endpoint";
import { useHistory } from "react-router-dom";
import ErrorMessage from "components/ErrorMessage";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#54bad1",
    },
    background: {
      default: "#111827",
      paper: "#111827",
    },
  },
});

const whiteTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#54bad1",
    },
  },
});

const PageSchedule = () => {
  const [schedule, setSchedule] = useState([]);
  // const [trainer, setTrainer] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [theme, setTheme] = useState(localStorage.theme);
  const [user, setUser] = useState<any>({});
  const [token, setToken] = useState<any>();
  const history = useHistory();
  const [formError, setFormError] = useState<any>();

  useEffect(() => {
    const userdetail = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_USERDETAIL_ECOSUNNAH) || "{}"
    );
    setUser(userdetail);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN_ECOSUNNAH);
    setToken(token);

    const getData = async () => {
      await axios.get(SCHEDULE_URL).then(({ data }) => {
        console.log("deda", data);
        const formattedData = data.map((item: any, index: any) => ({
          event_id: item.id,
          user: item.user_detail,
          horse: item.horse_detail,
          type: item.type,
          trainer: item.horse_detail?.trainer_detail,
          start: new Date(item.date + " " + item.start_time.replace(/-/g, "/")),
          end: new Date(item.date + " " + item.end_time.replace(/-/g, "/")),
          color:
            item.type === "Berkuda"
              ? item.status === "Pending"
                ? "#CB1C8D"
                : ""
              : item.status === "Pending"
              ? "#332FD0"
              : "#10A19D",
        }));

        setSchedule(formattedData);
      });

      setLoading(false);
    };

    getData();

    window.addEventListener("theme", () => {
      setTheme(localStorage.theme);
    });
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setFormLoading(true);

    const scheduleData = new FormData();
    scheduleData.append("date", moment(e.target[0].value).format("YYYY-MM-DD"));
    scheduleData.append(
      "start_time",
      moment(e.target[0].value).format("HH:mm")
    );
    scheduleData.append("end_time", moment(e.target[1].value).format("HH:mm"));
    scheduleData.append("type", e.target[2].value);
    // scheduleData.append("trainerId", e.target[3].value);
    scheduleData.append("userId", user.id);

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    axios
      .post(SCHEDULE_URL, scheduleData, options)
      .then((res) => {
        history.go(0);
      })
      .catch((err) => {
        setFormError(err.message);
      })
      .finally(() => {
        setFormLoading(false);
      });
  };

  if (loading) return null;
  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : whiteTheme}>
      <div className="nc-PageHome relative overflow-hidden">
        <Helmet>
          <title>Eco Sunnah Living</title>
        </Helmet>
        <BgGlassmorphism />

        <div className="container relative mt-12 mb-12 space-y-4">
          <p className="text-center text-2xl font-bold mb-3">
            Reservasi Berkuda dan Memanah
          </p>
          <div className="px-4">
            <ul className="list-decimal">
              <li>
                Silahkan pilih waktu berkuda atau memanah yang Anda inginkan
              </li>
              <li>
                Silahkan klik waktu yang sesuai pada tabel waktu dibawah ini
              </li>
              <li>Silahkan mengisi waktu yang sesuai</li>
              <li> Setelah itu, Silahkan klik tombol Konfirmasi</li>
              <li>
                Silahkan menunggu waktu yang anda ajukan untuk disetujui oleh
                pengelolah
              </li>
            </ul>
          </div>
        </div>
        <div className="container relative mt-12 mb-12 space-y-4">
          <div className="flex flex-row space-x-3 items-center justify-end">
            <p>Berkuda Disetujui</p>
            <div className="bg-primary-6000 w-5 h-5 rounded-full"></div>
          </div>
          <div className="flex flex-row space-x-3 items-center justify-end">
            <p>Memanah Disetujui</p>
            <div className="bg-[#10A19D] w-5 h-5 rounded-full"></div>
          </div>
          <div className="flex flex-row space-x-3 items-center justify-end">
            <p>Berkuda Pending</p>
            <div className="bg-[#CB1C8D] w-5 h-5 rounded-full"></div>
          </div>
          <div className="flex flex-row space-x-3 items-center justify-end">
            <p>Memanah Pending</p>
            <div className="bg-[#332FD0] w-5 h-5 rounded-full"></div>
          </div>
        </div>
        <div className="container relative mb-24 z-10">
          <Scheduler
            day={null}
            month={null}
            hourFormat="24"
            week={{
              weekDays: [0, 1, 2, 3, 4, 5, 6],
              weekStartOn: 6,
              startHour: 7,
              endHour: 11,
              step: 15,
              navigation: true,
              disableGoToDay: false,
              // cellRenderer: ({ height, start, onClick, ...props }) => {
              //   return (
              //     <Button
              //       style={{
              //         height: "100%",
              //       }}
              //     ></Button>
              //   );
              // }
            }}
            customEditor={(scheduler) => (
              <div className="p-7 flex-col items-center">
                <h2 className="text-center font-bold py-10">
                  Reservasi Sekarang
                </h2>
                <form
                  className="grid grid-cols-2 gap-6"
                  onSubmit={handleSubmit}
                >
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      Mulai
                    </span>
                    <Input
                      name="startDate"
                      type="datetime-local"
                      className="mt-1"
                      defaultValue={moment(scheduler.state.start.value).format(
                        'YYYY-MM-DDTHH:mm'
                      )}
                    />
                    {/* <Input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="example@example.com"
                      className="mt-1"
                    /> */}
                  </label>
                  <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      Akhir
                    </span>
                    <Input
                      name="endDate"
                      type="datetime-local"
                      className="mt-1"
                      defaultValue={moment(scheduler.state.end.value).format(
                        'YYYY-MM-DDTHH:mm'
                      )}
                    />
                    <span className="flex  justify-between items-center text-neutral-800 dark:text-neutral-200">
                      <div className="text-white"></div>
                    </span>
                  </label>
                  <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      Tipe
                    </span>
                    <Select className="mt-1">
                      <option value="Memanah">Memanah</option>
                      <option value="Berkuda">Berkuda</option>
                      {/* {horse.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))} */}
                    </Select>
                  </label>
                  {/* <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      Pelatih
                    </span>
                    <Select className="mt-1">
                      <option value={""}>---</option>
                      {trainer.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.user_detail?.fullname}
                        </option>
                      ))}
                    </Select>
                  </label> */}
                  <div className="flex items-center justify-center">
                    {formLoading && <Spinner />}
                  </div>
                  {formError ? (
                    <ErrorMessage className="col-start-1 row-start-3 col-span-2">
                      {formError}
                    </ErrorMessage>
                  ) : null}
                  {token ? (
                    <div className="col-start-2 !p-0">
                      <Button onClick={scheduler.close} className="!p-0">
                        Batal
                      </Button>
                      <Button type="submit" className="!p-0">
                        Konfirmasi
                      </Button>
                    </div>
                  ) : (
                    <ErrorMessage className="col-start-1 row-start-3 col-span-2">
                      Silahkan login terlebih dulu.{' '}
                      <a href="/login">Klik disini</a>
                    </ErrorMessage>
                  )}
                </form>
              </div>
            )}
            viewerExtraComponent={(fields, event) => {
              return (
                <div className="space-y-1 text-[#666666] text-xs">
                  <div className="flex flex-row space-x-2 items-center">
                    <AccountCircleOutlinedIcon />
                    <p>{event.user?.fullname}</p>
                  </div>
                  {event.type !== 'Memanah' ? (
                    <>
                      <div className="flex flex-row space-x-2 items-center">
                        <BedroomBabyOutlinedIcon />
                        <p>{event.horse?.name}</p>
                      </div>
                      <div className="flex flex-row space-x-2 items-center">
                        <CastForEducationOutlinedIcon />
                        <p>{event.type}</p>
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-row space-x-2 items-center">
                      <CastForEducationOutlinedIcon />
                      <p>{event.type}</p>
                    </div>
                  )}
                </div>
              )
            }}
            eventRenderer={(event) => {
              console.log(event.event)
              return (
                <div className={`text-white`} onClick={event.onClick}>
                  <p style={{ fontSize: '9.5px' }}>
                    {event?.event?.type} - {' '}
                    <b>
                      {moment(event?.event?.start).format('HH:mm')}-
                      {moment(event?.event?.end).format('HH:mm')}
                    </b>
                  </p>
                  {/* <div>{event.user?.fullname}</div>
                  <div>{event.type}</div> */}
                  {/* <div>{event.event}</div> */}
                  <p style={{ fontSize: '9.5px' }}>
                    {event?.event?.user?.fullname}
                  </p>
                </div>
              )
            }}
            editable={false}
            draggable={false}
            events={schedule}
            deletable={false}
            loading={loading}
          />
        </div>
      </div>
    </ThemeProvider>
  )
};

export default PageSchedule;
