// import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
// import React, {useState, useEffect} from "react";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
// import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
// import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
// import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
// import SectionVideos from "./SectionVideos";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { Helmet } from "react-helmet";
import SectionHero from "./SectionHero";


const Archery = () => {

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Eco Sunnah Living</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-28" />

        {/* SECTION 1 */}
        {/* <CommunityPage /> */}
        {/* SECTION2 */}
        {/* <SectionOurFeatures /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <HighlightProducts />
        </div> */}

        {/* SECTION */}
        {/* <SectionHowItWork /> */}

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places to stay that Chisfis recommends for you"
            sliderStyle="style2"
          />
        </div> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}

        {/* SECTION */}
        {/* <SectionGridCategoryBox /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div> */}

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
        /> */}

        {/* SECTION */}
        {/* <SectionVideos /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}
      </div>
    </div>
  );
}

export default Archery;
