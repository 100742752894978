import React, { FC } from 'react'
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from 'images/hero-right.jpg'
import CarouselComp from '../Carousel/CarouselComp'
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories'
import BackgroundSection from '../BackgroundSection/BackgroundSection'
import { TaxonomyType } from 'data/types'
import SectionSliderNewCompetitions from 'components/SectionSliderNewCategories/SectionSliderNewCompetitions'
// import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroProps {
  className?: string
}

const SectionHero: FC<SectionHeroProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      {/* <div className="flex flex-col lg:flex-row"> */}
      <div className="flex flex-col">
        {/* <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pt-11 pb-14 lg:pb-60 xl:pr-14 lg:mr-10 xl:mr-0"> */}
        <div className="flex flex-col lg:flex-row lg:items-center space-y-4 lg:space-y-0 lg:space-x-10">
          <div className="flex flex-1 flex-col items-start space-y-4">
            <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
              Eco Sunnah Living
            </h2>
            <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
              Daarus Sunnah MQ Equestrian adalah tempat yg di gagas oleh KH.
              Abdullah Gymnastiar (AA Gym) menjadi sarana bagi jamaah, santri
              dan juga Masyarakat umum untuk mengenal & mengamalkan olahraga
              yang disunnahkan oleh Rasulullah sallahu alaihi wasallam
            </span>
            {/* <ButtonPrimary>Mulai Sekarang</ButtonPrimary> */}
          </div>
          <CarouselComp />
          {/* <img className="w-full rounded-xl" src={imagePng} alt="hero" /> */}
          {/* <div className="flex flex-1">
        </div> */}
        </div>
        <div className="lg:mt-28 mt-16">
          <SectionSliderNewCategories
            // categories={DEMO_CATS_2}
            // categoryCardType="card4"
            itemPerRow={4}
            heading="Eco Sunnah Lomba dan Kegiatan"
            subHeading="Rangkaian acara tasyakur Milad Daarut Tauhiid ke 33 tahun, MQ Equestrian Daarus Sunnah menyelenggarakan acara perlombaan memanah, show jumping dan horseback archery pada tanggal 28-29 Oktober 2023"
            sliderStyle="style2"
          />
        </div>
        <div className="lg:mt-28 mt-16">
          <SectionSliderNewCompetitions
            // categories={DEMO_CATS_2}
            // categoryCardType="card4"
            itemPerRow={4}
            heading="Produk Unggulan Eco Sunnah"
            subHeading="Berkuda | Memanah"
            sliderStyle="style2"
          />
        </div>
      </div>

      {/* <div className="z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div> */}
    </div>
  )
}

export default SectionHero
