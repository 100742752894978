import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
import Heading from "../../../../shared/Heading/Heading";
import CardHighlight from "./CardHighlight";
import axios from "axios";
import ButtonPrimary from "../../../../shared/Button/ButtonPrimary";
import { PRODUCT_URL } from "../../../../utils/endpoint";
import { Tab } from "@headlessui/react";

export interface HighlightProductsProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const HighlightProducts: FC<HighlightProductsProps> = ({
  gridClass = "",
  heading = "Produk Kami",
  subHeading = "Beberapa produk unggulan kami",
  // headingIsCenter,
  tabs = ["New York", "Tokyo", "Paris", "London"],
}) => {
  const [categories, setCategories]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  const [products, setProducts]: any = useState([]);
  const [loadingProducts, setLoadingProducts]: any = useState(false);

  useEffect(() => {
    axios
      .get(`https://api.ghaidas.com/api-ghaidas/servicecategory/`)
      .then((res) => {
        // console.log(res.data);
        setCategories(res.data);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`https://api.ghaidas.com/api-ghaidas/availableproduct/`)
      .then((res) => {
        // console.log(res.data);
        setProducts(res.data);
        setLoadingProducts(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [filterProduct, setFilterProduct]: any = useState(products);

  const FilteredProduct = (data: any) => {
    const updateProduct =
      loadingProducts &&
      products.filter((dataNow: any) => {
        return dataNow.service_category_detail.name === data;
      });
    setFilterProduct(updateProduct);
  };

  // const renderCard = (stay: any) => {
  //   return <CardHighlight key={stay.id} data={stay} />;
  // };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <div className="flex flex-col relative">
        <Heading desc="Jangan tunda kesempatanmu untuk menikmati layanan kami di eco sunnah living">
          Nikmati liburan bersama kami
        </Heading>
        {/* <div className="flex items-center justify-between">
          <span className="hidden sm:block flex-shrink-0">
            <ButtonSecondary className="!leading-none">
              <span>View all</span>
              <i className="ml-3 las la-arrow-right text-xl"></i>
            </ButtonSecondary>
          </span>
        </div> */}
      </div>
      <Tab.Group>
        <Tab.List className="flex space-x-1 overflow-x-auto">
          <Tab>
            {({ selected }) => (
              <div
                className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                  selected
                    ? ' bg-primary-6000 text-white '
                    : 'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'
                } `}
                onClick={() => FilteredProduct(categories)}
              >
                Semua
              </div>
            )}
          </Tab>
          {categories.map((item: any) => (
            <Tab key={item.id} as={Fragment}>
              {({ selected }) => (
                <div
                  className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                    selected
                      ? ' bg-primary-6000 text-white '
                      : 'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'
                  } `}
                  onClick={() => FilteredProduct(item.name)}
                >
                  {item.name}
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div
              className={` mt-8 grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
            >
              {loadingProducts &&
                products.map((stay: any) => (
                  <CardHighlight key={stay.id} data={stay} />
                ))}
            </div>
          </Tab.Panel>
          {loading &&
            categories.map((item: any) => (
              <Tab.Panel key={item.id} className="">
                <div
                  className={` mt-8 grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
                >
                  {loadingProducts &&
                    filterProduct.map((stay: any) => (
                      <CardHighlight key={stay.id} data={stay} />
                    ))}
                </div>
              </Tab.Panel>
            ))}
        </Tab.Panels>
      </Tab.Group>
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      ></div>
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary href="/products/listing">
          Lihat Produk Lainnya
        </ButtonPrimary>
      </div>
    </div>
  )
};

export default HighlightProducts;
