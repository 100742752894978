import React, { FC, useEffect, useState } from "react";
import { StayDataType } from "data/types";
import StayCard from "./components/StayCard/StayCard";
import axios from "axios";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const [categories, setCategories]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://api.ghaidas.com/api-ghaidas/horse/`)
      .then((res) => {
        // console.log('res', res.data);
        setCategories(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <div className={`mb-12 lg:mb-16 ${className}`}>
        <h2 className="text-4xl font-semibold">Kuda di eco sunnah living</h2>

        {/* <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          233 stays
          <span className="mx-2">·</span>
          Aug 12 - 18
          <span className="mx-2">·</span>2 Guests
        </span> */}
      </div>

      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {loading ? (
          <div>loading...</div>
        ) : (
          categories.map((stay: any) => <StayCard key={stay.id} data={stay} />)
        )}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
