import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";

export interface CardCategory3Props {
  className?: string;
  taxonomy: any;
}

const CardCategory3: FC<CardCategory3Props> = ({
  className = "",
  taxonomy,
}) => {
  // console.log('taxonomy', taxonomy)
  // const { count, thumbnail } = taxonomy
  // console.log(taxonomy?.thumbnail)
  return (
    <Link
      to={`/products/detail/${taxonomy?.id}`}
      className={`nc-CardCategory3 flex flex-col ${className}`}
      data-nc-id="CardCategory3"
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage
          src={taxonomy?.thumbnail}
          className="object-cover w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 truncate">
        <span
          className={`block mt-2 text-lg font-semibold text-neutral-6000 text-center dark:text-neutral-400`}
        >
          {taxonomy?.name}
          {/* {convertNumbThousand(count || 0)} properties */}
        </span>
        <p className="text-center text-sm">{taxonomy?.location}</p>
      </div>
    </Link>
  );
};

export default CardCategory3;
