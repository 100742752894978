import React, { FC, useEffect, useState } from 'react'
import Heading from 'components/Heading/Heading'
import Glide from '@glidejs/glide'
import { TaxonomyType } from 'data/types'
import ncNanoId from 'utils/ncNanoId'
import CardCategory3 from 'components/CardCategory3/CardCategory3'
import CardCategory4 from 'components/CardCategory4/CardCategory4'
import NextPrev from 'shared/NextPrev/NextPrev'
import CardCategory5 from 'components/CardCategory5/CardCategory5'
import axios from 'axios'

export interface SectionSliderNewCompetitionsProps {
  className?: string
  itemClassName?: string
  heading?: string
  subHeading?: string
  categories?: TaxonomyType[]
  categoryCardType?: 'card3' | 'card4' | 'card5'
  itemPerRow?: 3 | 4 | 5
  sliderStyle?: 'style1' | 'style2'
}

const DEMO_CATS: any = [
  {
    id: '1',
    href: '#',
    name: 'Nature House',
    taxonomy: 'category',
    count: 17288,
    thumbnail:
      'https://images.pexels.com/photos/2581922/pexels-photo-2581922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  },
  {
    id: '2',
    href: '#',
    name: 'Wooden house',
    taxonomy: 'category',
    count: 2118,
    thumbnail:
      'https://images.pexels.com/photos/2351649/pexels-photo-2351649.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  },
  {
    id: '3',
    href: '#',
    name: 'Houseboat',
    taxonomy: 'category',
    count: 36612,
    thumbnail:
      'https://images.pexels.com/photos/962464/pexels-photo-962464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  },
  {
    id: '4',
    href: '#',
    name: 'Farm House',
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/248837/pexels-photo-248837.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  },
  {
    id: '5',
    href: '#',
    name: 'Dome House',
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/3613236/pexels-photo-3613236.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
  },
  {
    id: '6',
    href: '#',
    name: 'Dome House',
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/3613236/pexels-photo-3613236.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
  },
]

const SectionSliderNewCompetitions: FC<SectionSliderNewCompetitionsProps> = ({
  heading = 'Heading of sections',
  subHeading = 'Descriptions for sections',
  className = '',
  itemClassName = '',
  categories = DEMO_CATS,
  itemPerRow = 3,
  categoryCardType = 'card3',
  sliderStyle = 'style1',
}) => {
  const UNIQUE_CLASS = 'glide_' + ncNanoId()
  const [imgData, setImgData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  const dataCard = async () => {
    setLoading(true)
    try {
      const data = await axios.get(
        'https://api.ghaidas.com/api-ghaidas/productbycategory/?id_category=7,11'
      )
      setImgData(data.data)
      console.log('tesss', data.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if(imgData.length === 0){
      dataCard()
    }

    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      new Glide(`.${UNIQUE_CLASS}`, {
        perView: itemPerRow,
        gap: 32,
        bound: true,
        breakpoints: {
          1280: {
            perView: itemPerRow - 1,
          },
          1024: {
            gap: 20,
            perView: itemPerRow - 1,
          },
          768: {
            gap: 20,
            perView: itemPerRow - 2,
          },
          640: {
            gap: 20,
            perView: itemPerRow - 3,
          },
          500: {
            gap: 20,
            perView: 1,
          },
        },
      }).mount()
    }
    return () => {}
    // Get Data Card
    // dataCard()
  }, [imgData])


  console.log('img', imgData)

  const renderCard = (item: any, index: any) => {
    switch (categoryCardType) {
      case 'card3':
        return <CardCategory3 taxonomy={item} />
      case 'card4':
        return <CardCategory4 taxonomy={item} />
      case 'card5':
        return <CardCategory5 taxonomy={item} />
      default:
        return <CardCategory3 taxonomy={item} />
    }
  }
  // if(loading) return <p>Loaading</p>
  return (
    <div className={`nc-SectionSliderNewCompetitions  ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === 'style1'}
          isCenter={sliderStyle === 'style2'}
        >
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {imgData.map((item: any, index: any) => {
              // console.log('tes',item)
              return (
                <li key={index} className={`glide__slide ${itemClassName}`}>
                  {renderCard(item, index)}
                </li>
              )
            })}
          </ul>
        </div>

        {sliderStyle === 'style2' && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
    </div>
  )
}

export default SectionSliderNewCompetitions
