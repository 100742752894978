import { Tab } from "@headlessui/react";
import { FC, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
// import { useHistory } from 'react-router-dom';
import { PRODUCT_URL } from "../../../utils/endpoint";
import ProductsCard from "./ProductsCard";

export interface ProductListingProps {
  className?: string;
}
// const productDetailHandler = (history: any, listData: any) => {
//   console.log("send data", listData);
//   history.push(`/listing-product-detail/${listData.id}/`);
// };

const ProductListing: FC<ProductListingProps> = ({ className = "" }) => {
  // let [categories] = useState(["Stays", "Experiences", "Car for rent"]);
  // const history = useHistory();
  const [categories, setCategories]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  const [products, setProducts]: any = useState([]);
  const [loadingProducts, setLoadingProducts]: any = useState(false);

  useEffect(() => {
    axios
      .get(`https://api.ghaidas.com/api-ghaidas/servicecategory/`)
      .then((res) => {
          // console.log(res.data);
          setCategories(res.data);
          setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(`https://api.ghaidas.com/api-ghaidas/availableproduct/`)
      .then((res) => {
          // console.log(res.data);
          setProducts(res.data);
          setLoadingProducts(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const [filterProduct, setFilterProduct] : any = useState(products)

  const FilteredProduct = (data: any) => {
    const updateProduct = loadingProducts && products.filter((dataNow: any)=>{
      return dataNow.service_category_detail.name === data;
    })
    setFilterProduct(updateProduct)
  }

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Nikmati liburan bersama kami</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Penuh kebutuhanmu bersama kami di Eco Sunnah Living
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              <Tab>
              {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                      onClick={() => FilteredProduct(categories)}
                    >
                      Semua
                    </button>
                  )}
              </Tab>
              {categories.map((item: any) => (
                <Tab key={item.id} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                      onClick={() => FilteredProduct(item.name)}
                    >
                      {item.name}
                    </button>
                  )}
                </Tab>
              ))}

            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className={` mt-8 grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}>
                {loadingProducts && products.map((stay:any) => (
                    <ProductsCard key={stay.id} data={stay} />
                  ))}
                </div>
              </Tab.Panel>
              {loading && categories.map((item: any) => (
                <Tab.Panel className="">
                <div className={` mt-8 grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}>
                {loadingProducts && filterProduct.map((stay:any) => (
                    <ProductsCard key={stay.id} data={stay} />
                  ))}
                </div>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Eco Sunnah Living</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        {/* <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div> */}
        <div className="w-full lg:w-5/5 xl:w-3/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
          {/* {renderSection2()} */}
        </div>
      </main>
    </div>
  );
};

export default ProductListing;
