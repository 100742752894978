import React, { FC } from "react";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/archers.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      {/* <div className="flex flex-col lg:flex-row"> */}
      <div className="flex flex-col lg:flex-row lg:items-center space-y-8 lg:space-y-0 lg:space-x-10">
        {/* <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pt-11 pb-14 lg:pb-60 xl:pr-14 lg:mr-10 xl:mr-0"> */}
        <div className="flex flex-1 flex-col items-start space-y-4">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
            Memanah
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            Dapatkan pengalaman menyenangkan dan nikmati berbagai fasilitas
            memanah menarik bersama Eco Sunnah Living. Reservasi sekarang dan
            kamu akan menemukan suasana yang tidak didapatkan di tempat lain.
          </span>
          <ButtonPrimary href="/schedule" className="mt-6 sm:mt-11">
          Reservasi Sekarang
        </ButtonPrimary>
        </div>
        <div className="flex flex-1">
          <img className="w-full rounded-xl" src={imagePng} alt="hero" />
        </div>

      </div>
    </div>
  );
};

export default SectionHero;
