import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export interface PageLogoutProps {
  className?: string;
}

const PageLogout: FC<PageLogoutProps> = ({ className = "" }) => {

  return (
    <div className={`nc-PageLogout ${className}`} data-nc-id="PageLogout">
      <Helmet>
        <title>Eco Sunnah Living</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Logged-out
        </h2>
        
        <div>
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <h3>Let's go back </h3>
            <Link className="text-2xl" to="/login">SIGN IN</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogout;
