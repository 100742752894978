import { Tab } from '@headlessui/react'
import { PencilAltIcon } from '@heroicons/react/outline'
import { FC, useEffect, useState } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import NcImage from 'shared/NcImage/NcImage'
import NcModal from 'shared/NcModal/NcModal'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'
import { ORDER_URL, PRODUCT_URL } from '../../utils/endpoint'
import Input from 'shared/Input/Input'
import Select from 'shared/Select/Select'
import moment from 'moment'

import {
  LOCALSTORAGE_TOKEN_ECOSUNNAH,
  LOCALSTORAGE_USERDETAIL_ECOSUNNAH,
} from '../../../src/utils/Types'
import { Spinner } from 'reactstrap'

export interface CheckOutPageRaferalProps {
  className?: string
}

const CheckOutPageRaferal: FC<CheckOutPageRaferalProps> = ({ className = '' }) => {
  const params: any = useParams()

  const [products, setProducts]: any = useState([])
  const [bookingDate, setBookingDate] = useState(moment().format('YYYY-MM-DD'))
  const [numGuest, setNumGuest] = useState(1)
  const [loading, setLoading]: any = useState(false)
  useEffect(() => {
    // console.log(products)
    axios
      .get(`${PRODUCT_URL}${params.id}`)
      .then((res) => {
        // console.log(res.data);
        setProducts(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [params.id])
  // console.log('book', bookingDate)
  const otherForms = Array.from({ length: numGuest }, (_, i) => (
    <>
      <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
        <div className="flex-1 p-5 flex justify-between space-x-5">
          <div className="flex flex-col">
            <span className="text-sm text-neutral-400">Umur {i + 1}</span>
            <span>
              <Input
                name={`age${i + 1}`}
                // onChange={(e) => setBookingDate(e.target.value)}
                className="mt-1.5 text-lg font-semibold"
                type="number"
                // defaultValue={bookingDate}
                placeholder="Masukan Umur"
                required
              />
            </span>
          </div>
          {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
        </div>
        <div className="flex-1 p-5 flex justify-between space-x-5">
          <div className="flex flex-col">
            <span className="text-sm text-neutral-400">Berat {i + 1}</span>
            <span>
              <Input
                name={`weight${i + 1}`}
                // onChange={(e) => setBookingDate(e.target.value)}
                className="mt-1.5 text-lg font-semibold"
                type="number"
                // defaultValue={bookingDate}
                placeholder="Masukan Berat"
                required
              />
            </span>
          </div>
          {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
        </div>
        <div className="flex-1 p-5 flex justify-between space-x-5">
          <div className="flex flex-col">
            <span className="text-sm text-neutral-400">Gender {i + 1}</span>
            <span>
              <Select
                // onChange={(e) => setBookingDate(e.target.value)}
                className="mt-1.5 text-lg font-semibold"
                name={`gender${i + 1}`}
                required
                // defaultValue={bookingDate}
              >
                <option value={''}>Pilih Gender</option>
                <option value={'Laki Laki'}>Laki-Laki</option>
                <option value={'Perempuan'}>Perempuan</option>
              </Select>
            </span>
          </div>
          {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
        </div>
      </div>
    </>
  ))
  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={products.thumbnail} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {products.unit}
              </span>
              <span className="text-base font-medium mt-1 block">
                {products.name}
              </span>
            </div>
            {loading &&
              products.facility_detail.map((facility: any) => (
                <span
                  key={facility.id}
                  className="block  text-sm text-neutral-500 dark:text-neutral-400"
                >
                  {facility.name}
                </span>
              ))}
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>
              {products.unit === 'Room' || products.unit === 'Family' ? (
                <NumberFormat
                  value={products.price_idr * 1}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'Rp'}
                />
              ) : (
                <NumberFormat
                  value={products.price_idr * numGuest}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'Rp'}
                />
              )}
            </span>
          </div>
        </div>
      </div>
    )
  }

  const history = useHistory()
  const checkOutHandler = (e: any,) => {
    console.log('params', params, params)
    console.log('masukkk')
    // get user detail, redirect to signup if not available
    e.preventDefault()
    const userdetail = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_USERDETAIL_ECOSUNNAH) || '{}'
    )
    if (userdetail.fullname) {
      const data = {
        amount: products.price_idr,
        user_id: userdetail.id,
        product_id: products.id,
        status: 'Belum Lunas',
        tanggal_book: bookingDate,
        jumlah_pax: numGuest,
        description_1:
          `BB1~${
            e.target.weight1 !== undefined ? e.target.weight1.value : '0'
          }` +
          `,BB2~${
            e.target.weight2 !== undefined ? e.target.weight2.value : '0'
          }` +
          `,BB3~${
            e.target.weight3 !== undefined ? e.target.weight3.value : '0'
          }` +
          `,BB4~${
            e.target.weight4 !== undefined ? e.target.weight4.value : '0'
          }` +
          `,BB5~${
            e.target.weight5 !== undefined ? e.target.weight5.value : '0'
          }`,
        description_2:
          `AGE1~${e.target.age1 !== undefined ? e.target.age1.value : '0'}` +
          `,AGE2~${e.target.age2 !== undefined ? e.target.age2.value : '0'}` +
          `,AGE3~${e.target.age3 !== undefined ? e.target.age3.value : '0'}` +
          `,AGE4~${e.target.age4 !== undefined ? e.target.age4.value : '0'}` +
          `,AGE5~${e.target.age5 !== undefined ? e.target.age5.value : '0'}`,
        description_3:
          `GENDER1~${
            e.target.gender1 !== undefined ? e.target.gender1.value : '0'
          }` +
          `,GENDER2~${
            e.target.gender2 !== undefined ? e.target.gender2.value : '0'
          }` +
          `,GENDER3~${
            e.target.gender3 !== undefined ? e.target.gender3.value : '0'
          }` +
          `,GENDER4~${
            e.target.gender4 !== undefined ? e.target.gender4.value : '0'
          }` +
          `,GENDER5~${
            e.target.gender5 !== undefined ? e.target.gender5.value : '0'
          }`,
        is_manual_payment: false,
        transaction_fee: 4000,
        vat_fee: 440,
        platform_fee: 1500,
        referral_marketing: params.raferal,
      }

      // console.log(data);
      const token: any = localStorage.getItem(LOCALSTORAGE_TOKEN_ECOSUNNAH)
      // console.log(token);
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
      }
      const postData = async () => {
        setLoading(true)
        try {
          const upload = await axios.post(ORDER_URL, data, options)
          setLoading(false)
          history.push('/shopping-detail/' + upload.data.id)
        } catch (err) {
          setLoading(false)
          console.log('[ERROR in ORDER]', err)
        }
      }
      postData()
      // axios
      //   .post(ORDER_URL, data, options)
      //   .then((res) => {
      //     console.log(res.data);
      //     setLoading(false);
      //     history.push('/shopping-detail/'+res.data.id)
      //   })
      //   .catch((error) => {
      //     setLoading(false);
      //     console.log('[ERROR in ORDER]', error)
      //   })
    } else {
      history.push(`/login/checkout-${params.id}`)
    }
  }

  // const renderMain = () => {
  //   return (
  //     <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
  //       <h2 className="text-3xl lg:text-4xl font-semibold">
  //         Confirm and payment
  //       </h2>
  //       <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
  //       <div>
  //         <div>
  //           <h3 className="text-2xl font-semibold">{products.name}</h3>
  //           <NcModal
  //             renderTrigger={(openModal) => (
  //               <span
  //                 onClick={() => openModal()}
  //                 className="block lg:hidden underline  mt-1 cursor-pointer"
  //               >
  //                 View booking details
  //               </span>
  //             )}
  //             renderContent={renderSidebar}
  //           />
  //         </div>
  //         <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
  //           <div className="flex-1 p-5 flex justify-between space-x-5">
  //             <div className="flex flex-col">
  //               <span className="text-sm text-neutral-400">Date</span>
  //               <span>
  //                 <Input
  //                   onChange={(e) => setBookingDate(e.target.value)}
  //                   className="mt-1.5 text-lg font-semibold"
  //                   type="date"
  //                   defaultValue={bookingDate}
  //                 />
  //               </span>
  //             </div>
  //             {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
  //           </div>
  //           <div className="flex-1 p-5 flex justify-between space-x-5">
  //             <div className="flex flex-col">
  //               <span className="text-sm text-neutral-400">
  //                 {products.unit}
  //               </span>
  //               <span className="mt-1.5 text-lg font-semibold">
  //                 <Input
  //                   type="number"
  //                   onChange={(e) => setNumGuest(parseInt(e.target.value))}
  //                   defaultValue={numGuest}
  //                   max="5"
  //                 />
  //               </span>
  //             </div>
  //             <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" />
  //           </div>
  //         </div>
  //       </div>
  //       <h3 className="text-2xl font-semibold">Detail Person</h3>
  //       {otherForms}
  //       <div>
  //         <div className="mt-6">
  //           <Tab.Group>
  //             {/* <div className="w-14 border-b border-neutral-200 my-5"></div> */}
  //             <div className="pt-4">
  //               <ButtonPrimary onClick={checkOutHandler}>
  //                 Confirm and pay
  //               </ButtonPrimary>
  //             </div>
  //           </Tab.Group>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  const createMarkup = (htmlString: string) => {
    return { __html: htmlString }
  }

  return (
    <div className={`nc-CheckOutPageRaferal ${className}`} data-nc-id="CheckOutPageRaferal">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <form className="flex" onSubmit={checkOutHandler}>
          <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
              <h2 className="text-3xl lg:text-4xl font-semibold">
                Confirm and payment
              </h2>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div>
                <div
                  dangerouslySetInnerHTML={createMarkup(products?.description)}
                  className="mb-8"
                ></div>
                <div>
                  <h3 className="text-2xl font-semibold">{products?.name}</h3>
                  <NcModal
                    renderTrigger={(openModal) => (
                      <span
                        onClick={() => openModal()}
                        className="block lg:hidden underline  mt-1 cursor-pointer"
                      >
                        View booking details
                      </span>
                    )}
                    renderContent={renderSidebar}
                  />
                </div>
                <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                  <div className="flex-1 p-5 flex justify-between space-x-5">
                    <div className="flex flex-col">
                      <span className="text-sm text-neutral-400">Date</span>
                      <span>
                        <Input
                          onChange={(e) => setBookingDate(e.target.value)}
                          className="mt-1.5 text-lg font-semibold"
                          type="date"
                          defaultValue={bookingDate}
                        />
                      </span>
                    </div>
                    {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
                  </div>
                  <div className="flex-1 p-5 flex justify-between space-x-5">
                    <div className="flex flex-col">
                      <span className="text-sm text-neutral-400">
                        {products.unit}
                      </span>
                      <span className="mt-1.5 text-lg font-semibold">
                        <Input
                          type="number"
                          onChange={(e) =>
                            setNumGuest(parseInt(e.target.value))
                          }
                          defaultValue={numGuest}
                          max="5"
                        />
                      </span>
                    </div>
                    <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" />
                  </div>
                </div>
              </div>
              {products.service_category_detail?.name === 'Horse Riding' ||
              products.service_category_detail?.name === 'Archery' ? (
                <h3 className="text-2xl font-semibold">Detail Person</h3>
              ) : null}
              {products.service_category_detail?.name === 'Horse Riding' ||
              products.service_category_detail?.name === 'Archery'
                ? otherForms
                : null}
              {products.service_category_detail?.name === 'Horse Riding' ||
              products.service_category_detail?.name === 'Archery' ? (
                <div>
                  <h3>Catatan!</h3>
                  <ul className='list-disc'>
                    <li>Program Riding Class Maksimal Berat Badan 80Kg</li>
                    <li>Program Pony Ride Maksimal Umur 7 Tahun</li>
                  </ul>
                </div>
              ) : null}

              <div>
                <div>
                  <Tab.Group>
                    {/* <div className="w-14 border-b border-neutral-200 my-5"></div> */}
                    <div className="pt-4 w-48">
                      <ButtonPrimary disabled={loading ? true : false}>
                        {loading ? <Spinner /> : 'Confirm and Pay'}
                      </ButtonPrimary>
                    </div>
                  </Tab.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block flex-grow">
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
              <div className="flex flex-col sm:flex-row sm:items-center">
                <div className="flex-shrink-0 w-full sm:w-40">
                  <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                    <NcImage src={products.thumbnail} />
                  </div>
                </div>
                <div className="py-5 sm:px-5 space-y-3">
                  <div>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                      {products.unit}
                    </span>
                    <span className="text-base font-medium mt-1 block">
                      {products.name}
                    </span>
                  </div>
                  {loading &&
                    products.facility_detail.map((facility: any) => (
                      <span
                        key={facility.id}
                        className="block  text-sm text-neutral-500 dark:text-neutral-400"
                      >
                        {facility.name}
                      </span>
                    ))}
                  <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                </div>
              </div>
              <div className="flex flex-col space-y-4">
                <h3 className="text-2xl font-semibold">Price detail</h3>

                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex justify-between font-semibold">
                  <span>Total</span>
                  <span>
                    {products.unit === 'Room' || products.unit === 'Family' ? (
                      <NumberFormat
                        value={products.price_idr * 1}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp'}
                      />
                    ) : (
                      <NumberFormat
                        value={products.price_idr * numGuest}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp'}
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
  )
}

export default CheckOutPageRaferal
