import React from 'react'
import { Carousel } from 'antd'
import imagePng from 'images/imageCarousel/111.png'
import imagePng2 from 'images/imageCarousel/112.png'
import imagePng3 from 'images/imageCarousel/113.png'
import imagePng4 from 'images/imageCarousel/114.png'
import imagePng5 from 'images/imageCarousel/DS-28.jpg'
import imagePng6 from 'images/imageCarousel/DS-43.jpg'

const CarouselComp: React.FC = () => (
  <div className='lg:w-1/2 w-full'>
    <Carousel autoplay>
      <div>
        <img className="w-full rounded-xl" src={imagePng} alt="hero" />
      </div>
      <div>
        <img className="w-full rounded-xl" src={imagePng2} alt="hero" />
      </div>
      <div>
        <img className="w-full rounded-xl" src={imagePng3} alt="hero" />
      </div>
      <div>
        <img className="w-full rounded-xl" src={imagePng4} alt="hero" />
      </div>
      {/* <div>
        <img className="w-full rounded-xl" src={imagePng5} alt="hero" />
      </div>
      <div>
        <img className="w-full rounded-xl" src={imagePng6} alt="hero" />
      </div> */}
    </Carousel>
  </div>
)

export default CarouselComp
