import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
// import Select from "shared/Select/Select";
import axios from "axios";
// import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Alert, Spinner } from "reactstrap";
// import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LOCALSTORAGE_USERDETAIL_ECOSUNNAH, LOCALSTORAGE_TOKEN_ECOSUNNAH } from "utils/Types";
import { AUTH_URL_USERME } from "utils/endpoint";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  // const [user, setUser]: any = useState("no");
  const [fullname, setFullname]: any = useState("");
  const [phone, setPhone]: any = useState("");
  const [email, setEmail]: any = useState("");
  // const [address, setAddress]: any = useState("");
  const [photo, setPhoto]: any = useState("");
  const [loadingUser, setLoadingUser]: any = useState(false);

  const [loading, setLoading] = useState(false);
  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  // const history = useHistory();
  const errorHandling = (msg: any) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  };
  useEffect(() => {
    const userdetail = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL_ECOSUNNAH) || "{}");
    // setUser(userdetail);
    setFullname(userdetail.fullname);
    setPhone(userdetail.phone);
    setEmail(userdetail.email);
    // setAddress(userdetail.address);
    setPhoto(userdetail.photo);
    setLoadingUser(true);
  }, []);
  // console.log(user);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = new FormData();
    data.append("fullname", e.target.fullname.value);
    data.append("phone", e.target.phone.value);
    data.append("email", e.target.email.value);
    if ([e.target.photo][0].files[0]) {
      data.append("photo", [e.target.photo][0].files[0]);
    }

    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN_ECOSUNNAH);
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };

    axios
      .patch(AUTH_URL_USERME, data, options)
      .then((userData: any) => {
        errorHandling("posting data berhasil");
        localStorage.setItem(LOCALSTORAGE_USERDETAIL_ECOSUNNAH, JSON.stringify(userData.data));
        // history.push({
        //   pathname: "/dashboard/edit-profile",
        // });
        window.location.reload();
        setLoading(false);
      })
      .catch((error) => {
        errorHandling(error.response.data);
        setLoading(false);
      })
      .catch((error) => {
        errorHandling(error.response.data);
      });
  };
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Eco Sunnah Living</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account infomation</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar imgUrl={loadingUser && photo} sizeClass="w-32 h-32" />
                {/* <img src={loadingUser && photo} alt=""/> */}
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer"></div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
                <div>
                  <Label>Name</Label>
                  <Input
                    className="mt-1.5"
                    name="fullname"
                    value={fullname}
                    onChange={(e) => {
                      setFullname(e.target.value);
                    }}
                  />
                </div>
                {/* ---- */}
                {/* <div>
                <Label>Gender</Label>
                <Select className="mt-1.5">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
              </div> */}
                {/* ---- */}
                {/* <div>
                <Label>Username</Label>
                <Input className="mt-1.5" defaultValue="@eden_tuan" />
              </div> */}
                {/* ---- */}
                <div>
                  <Label>Email</Label>
                  <Input
                    className="mt-1.5"
                    value={email}
                    name="email"
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                {/* ---- */}
                <div className="max-w-lg">
                  <Label>Phone</Label>
                  <Input
                    className="mt-1.5"
                    type="tel"
                    name="phone"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </div>
                {/* ---- */}
                {/* <div>
                <Label>Addess</Label>
                <Input
                  className="mt-1.5"
                  type="address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div> */}
                {/* ---- */}
                <div>
                  <Label>Photo</Label>
                  <Input className="mt-1.5" type="file" name="photo" accept="image/png, image/jpeg" />
                </div>
                {/* ----
              {/* <div>
                <Label>About you</Label>
                <Textarea className="mt-1.5" defaultValue="..." />
              </div> */}
                {isAlertOn && (
                  <Alert onClick={() => setAlert(false)} color="danger">
                    {errorMsg}
                  </Alert>
                )}
                {loading ? <Spinner color="success" /> : ""}

                <div className="pt-2">
                  <ButtonPrimary>Update info</ButtonPrimary>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
