import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import Flayer from "images/flyer.png";

const EventRegistration = () => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  return (
    <div>
      <form>
        <div className="flex flex-col items-center min-h-screen pt-6 sm:justify-center sm:pt-0 bg-gray-50 ">
          <div className="px-6 py-4">
            <div className="flex items-center justify-center">
              <div className="w-56 lg:w-96 flex-shrink-0">
                <img src={Flayer} alt="Flyer" />
              </div>
            </div>
            <h2 className="my-5 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Form Registration
            </h2>
          </div>
          <div className="w-full px-6 py-4 mt-6 mb-36 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg ">
            <form className="w-full ">
              <div>
                <label className="block text-neutral-800 dark:text-neutral-200 ">
                  Nama Lomba
                </label>
                <div className="flex flex-col items-start">
                  <input
                    type="text"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label className="block text-neutral-800 dark:text-neutral-200 ">
                  Nama
                </label>
                <div className="flex flex-col items-start">
                  <input
                    type="text"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label className="block text-neutral-800 dark:text-neutral-200 ">
                  No. HP
                </label>
                <div className="flex flex-col items-start">
                  <input
                    type="text"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label className="block text-neutral-800 dark:text-neutral-200 ">
                  Email
                </label>
                <div className="flex flex-col items-start">
                  <input
                    type="text"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "
                  />
                </div>
              </div>
              <div className="mt-4 ">
                <label className="block text-neutral-800 dark:text-neutral-200 ">
                  Harga
                </label>
                <div className="flex flex-col items-start">
                  <input
                    type="text"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div className="flex items-center justify-end mt-4">
                <button
                  type="button"
                  onClick={openModal}
                  className="bg-primary-6000 hover:bg-primary-700 text-neutral-50 rounded-full py-2 px-4"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </form>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Pendaftaran Anda berhasil!
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Silahkan cek email Anda untuk mendapatkan informasi
                      selengkapnya!!!
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-full border border-transparent bg-primary-6000 hover:bg-primary-700 px-4 py-2 text-sm font-medium text-neutral-50 focus:outline-none "
                      onClick={closeModal}
                    >
                      Baik, terimakasih!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default EventRegistration;
