import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import { Alert } from "shared/Alert/Alert";
import { AUTH_URL_SIGNUP, AUTH_URL_LOGIN, AUTH_URL_USERME } from "../../../src/utils/endpoint";
import { Link } from "react-router-dom";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Spinner, Alert } from "reactstrap";
import { LOCALSTORAGE_USERDETAIL_ECOSUNNAH } from '../../../src/utils/Types';

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const params: any = useParams();
  const errorHandling = (msg: any) => {
    // console.log()
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(() => { setAlert(false); }, 5000)
  };
  const signUpHandle = (e: any) => {
    e.preventDefault();
    if(e.target[3].value !== e.target[4].value){
      errorHandling("Konfirmasi password tidak sama. Silahkan cek kembali");
      return;
    }

    const data = new FormData();
    data.append('fullname', e.target.fullname.value);
    data.append('email', e.target.email.value);
    data.append('phone', e.target.phone.value);
    data.append('password', e.target.password.value);
    data.append('re_password', e.target.re_password.value);
    data.append('role', 'Pengunjung');
    data.append('is_active', false.toString());
    data.append('is_staff', false.toString());
    setLoading(true);
    axios.post(AUTH_URL_SIGNUP, data)
      .then((response) => {
        setLoading(false);
        errorHandling('Registrasi Berhasil')
        const login = new FormData();
        login.append('email', e.target.email.value);
        login.append('password', e.target.password.value);
        setLoading(true);
        axios.post(AUTH_URL_LOGIN, login)
          .then((res) => {
            // authentication
            const token = res.data.auth_token;
            if (token) {
              localStorage.setItem("LOCALSTORAGE_TOKEN_ECOSUNNAH", token);
              // authorization
              axios.get(AUTH_URL_USERME, { headers: { Authorization: `Token ${token}` } }).then((userData: any) => {
                // const userdetail = userData.data;
                setLoading(false);
                // console.log(userdetail);
                // if (userdetail.status === 'ADMIN') {
                localStorage.setItem(LOCALSTORAGE_USERDETAIL_ECOSUNNAH, JSON.stringify(userData.data));
                if(params.url){
                  const args =  params.url.split('-');
                  history.push(`/${args[0]}/${args[1]}/`)
                  window.location.reload()
                }else{
                  history.push('/shoppingbag');
                  window.location.reload()
                }
              })
            }
          }).catch((error) => {
            errorHandling(error.response.data);
          });
      }).catch((error) => {
        errorHandling(error.response.data);
      });
  }
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Eco Sunnah Living</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Daftar Akun
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={signUpHandle} action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Nama Lengkap
              </span>
              <Input
                type="text"
                placeholder="nama lengkap anda"
                className="mt-1"
                name="fullname"
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Alamat Email
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                name="email"
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                No.Handphone
              </span>
              <Input
                type="text"
                placeholder="misal: 08XX-XXXX-XXXX"
                className="mt-1"
                name="phone"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password" className="mt-1" name="password"/>
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Ulangi Password
              </span>
              <Input type="password" className="mt-1" name="re_password" />
            </label>
            {isAlertOn && <Alert onClick={() => setAlert(false)} type="danger">{errorMsg}</Alert>}
            <div className="flex items-center justify-center">
              {isLoading && <Spinner />}
            </div>
            <ButtonPrimary type="submit">Daftar</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
           Sudah punya Akun? {` `}
            <Link to={params.url?`/login/${params.url.split('-')[0]}-${params.url.split('-')[1]}`: "/login"}>Masuk</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
