import React, { FC, useEffect, useState, Fragment } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
// import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { LOCALSTORAGE_USERDETAIL_ECOSUNNAH } from "utils/Types";
import { useHistory } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

export interface MainNav1Props {
  isTop: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const history = useHistory();
  const [user, setUser]: any = useState("no");
  useEffect(() => {
    const userdetail = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL_ECOSUNNAH) || "{}");
    setUser(userdetail);
  }, []);
  // console.log("eee", user.fullname);
  return (
    <div className={`nc-MainNav1 relative z-10 ${isTop ? "onTop " : "notOnTop backdrop-filter"}`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center lg:flex space-x-1">
            <SwitchDarkMode />
            {/* <SearchDropdown /> */}
            <div className="px-1" />
            {user.fullname !== undefined ? (
              <>
                {/* <div>{user.fullname}</div> */}
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                      {user.fullname}
                      <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a href="/account" className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
                              Account settings
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a href="/shoppingbag" className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
                              Checkout
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                localStorage.clear();
                                history.push({
                                  pathname: "/",
                                });
                                window.location.reload();
                              }}
                              className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block w-full text-left px-4 py-2 text-sm")}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </>
            ) : (
              <ButtonPrimary href="/login">Masuk/Daftar</ButtonPrimary>
            )}
          </div>
          <div className="flex items-center lg:hidden">
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
