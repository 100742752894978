import React, {Fragment, useState, useEffect} from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink, useHistory } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { Menu, Transition } from "@headlessui/react";
import { LOCALSTORAGE_USERDETAIL_ECOSUNNAH } from "utils/Types";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const NavMobile: React.FC<NavMobileProps> = ({ data = NAVIGATION_DEMO, onClickClose }) => {
  const history = useHistory();
  const [user, setUser]: any = useState("no");
  useEffect(() => {
    const userdetail = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL_ECOSUNNAH) || "{}");
    setUser(userdetail);
  }, []);
  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              exact
              strict
              to={{
                pathname: i.href || undefined,
              }}
              className="flex px-4 py-2.5 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-[2px]"
              activeClassName="text-secondary"
            >
              <span className={!i.children ? "block w-full" : ""} onClick={onClickClose}>
                {i.name}
              </span>
              {i.children && (
                <span className="block flex-grow" onClick={(e) => e.preventDefault()}>
                  <Disclosure.Button as="span" className="flex justify-end flex-grow">
                    <ChevronDownIcon className="ml-2 h-4 w-4 text-neutral-500" aria-hidden="true" />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure key={item.id} as="li" className="text-neutral-900 dark:text-white">
        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
          to={{
            pathname: item.href || undefined,
          }}
          activeClassName="text-secondary"
        >
          <span className={!item.children ? "block w-full" : ""} onClick={onClickClose}>
            {item.name}
          </span>
          {item.children && (
            <span className="block flex-grow" onClick={(e) => e.preventDefault()}>
              <Disclosure.Button as="span" className="flex justify-end flex-grow">
                <ChevronDownIcon className="ml-2 h-4 w-4 text-neutral-500" aria-hidden="true" />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.children && <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>}
      </Disclosure>
    );
  };

  return (
    <div className="overflow-y-auto w-full max-w-sm h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <Logo />
        <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
          <span>Dapatkan pengalaman menyenangkan dan nikmati berbagai fasilitas menarik bersama Eco Sunnah Living. Reservasi sekarang dan kamu akan menemukan suasana yang tidak didapatkan di tempat lain.</span>

          <div className="flex justify-between items-center mt-4">
            <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
            <span className="block">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {data.map(_renderItem)}
        {user.fullname !== undefined ? (
          <>
            {/* <div>{user.fullname}</div> */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                  {user.fullname}
                  <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a href="/account" className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
                          Account settings
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a href="/shoppingbag" className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
                          Checkout
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            localStorage.clear();
                            history.push({
                              pathname: "/",
                            });
                            window.location.reload();
                          }}
                          className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block w-full text-left px-4 py-2 text-sm")}
                        >
                          Sign out
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </>
        ) : (
          <ButtonPrimary href="/login">Masuk/Daftar</ButtonPrimary>
        )}
      </ul>

      {/* <div className="flex items-center justify-between py-6 px-5 space-x-4">
        <a href="/#" target="_blank" rel="noopener noreferrer">
          <ButtonPrimary>Get Template</ButtonPrimary>
        </a>
      </div> */}
    </div>
  );
};

export default NavMobile;
