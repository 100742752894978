import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios';
import {AUTH_URL_LOGIN, AUTH_URL_USERME} from "../../../src/utils/endpoint";
import { Spinner, Alert } from "reactstrap";
import {
  LOCALSTORAGE_TOKEN_ECOSUNNAH,
  LOCALSTORAGE_USERDETAIL_ECOSUNNAH,
} from '../../../src/utils/Types';

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const params: any = useParams();
  const errorHandling = (msg: any) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(()=> {setAlert(false);}, 10000)
  };

  const loginHandle = (e: any) => {
    e.preventDefault();

    const data = new FormData();
    data.append('email', e.target[0].value);
    data.append('password', e.target[1].value);
    setLoading(true);
    axios.post(AUTH_URL_LOGIN, data)
      .then((res) => {
        // authentication
        const token = res.data.auth_token;
        // console.log('token', token)
        if(token){
          localStorage.setItem(LOCALSTORAGE_TOKEN_ECOSUNNAH, token);
           // authorization
          axios.get(AUTH_URL_USERME, { headers: { Authorization: `Token ${token}` } }).then((userData: any) => {
            // const userdetail = userData.data;
            setLoading(false);
            localStorage.setItem(LOCALSTORAGE_USERDETAIL_ECOSUNNAH, JSON.stringify(userData.data));
            if(params.url){
              const args =  params.url.split('-');
              history.push(`/${args[0]}/${args[1]}/`)
              window.location.reload()
            }else{
              history.push('/shoppingbag');
              window.location.reload()
            }
          })
        }
        }).catch((error) => {
          errorHandling(error.response.data);
        });

  }
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Eco Sunnah Living</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Masuk
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {isAlertOn && <Alert onClick={()=>setAlert(false) } type="danger">{errorMsg}</Alert>}
          <form className="grid grid-cols-1 gap-6" onSubmit={loginHandle} >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Alamat Email
              </span>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="example@example.com"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input name="password" type="password" className="mt-1" />
              <span className="flex  justify-between items-center text-neutral-800 dark:text-neutral-200">
                <div className="text-white"></div>
                {/* <Link to="/forgot-pass" className=" flex justify-between items-center text-sm">
                  Forgot password?
                </Link> */}
              </span>

            </label>
            <div className="flex items-center justify-center">
            {isLoading && <Spinner />}
            </div>
            <ButtonPrimary type="submit">Masuk</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Belum punya akun? {` `}
            <Link to={params.url?`/signup/${params.url.split('-')[0]}-${params.url.split('-')[1]}`: "/signup"}>Daftar Sekarang</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
