// import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
// import React, {useState, useEffect} from "react";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
// import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
// import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
// import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
// import SectionVideos from "./SectionVideos";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { Helmet } from "react-helmet";
// import CommunityPage from "./components/CommunityPage/CommunityPage";
import HighlightProducts from "./components/HighlightProduct/HighlightProducts";
import SectionHero from "./components/SectionHero/SectionHero";
import BackgroundSection from "./components/BackgroundSection/BackgroundSection";
import SectionHowItWork from "./components/SectionHowItWork/SectionHowItWork";
import BgGlassmorphism from "./components/BgGlassmorphism/BgGlassmorphism";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import { useEffect, useState } from "react";
import axios from "axios";
import { FACILITY_URL } from "utils/endpoint";

const PageHome = () => {
    const [product, setProduct]: any = useState([])
    const [loading, setLoading]: any = useState(false)
    // console.log("send data", product);
    // const getUser: any = localStorage.getItem(LOCALSTORAGE_USERDETAIL_ECOSUNNAH)

    useEffect(() => {
      axios
        .get(`${FACILITY_URL}`)
        .then((res: any) => {
          console.log('list product',res.data)
          setProduct(res.data)
          setLoading(true)
        })
        .catch((error: any) => {
          console.log(error.response.data)
        })
    }, [])
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Eco Sunnah Living</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-" />

        {/* SECTION 1 */}
        {/* <CommunityPage /> */}

        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION */}
        <div className="relative py-8">
          <BackgroundSection />
          <HighlightProducts />
        </div>

        {/* SECTION */}
        <SectionHowItWork />

        {/* SECTION */}
        <SectionGridCategoryBox categories={product}/>

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places to stay that Chisfis recommends for you"
            sliderStyle="style2"
          />
        </div> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div> */}

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
        /> */}

        {/* SECTION */}
        {/* <SectionVideos /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}
      </div>
    </div>
  )
};

export default PageHome;
